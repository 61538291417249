<template>
    <div>
        <div class="container">
            <div class="logo">
                <img class="img-fluid" src="../../assets/images/logo/logo_756.png" alt="logo">
                <p>渥康产品</p>
            </div>
            <div class="row" v-for="(item,index) in productList" :key="index">
                <subtitle class="line-height pointer" :title="item.title" :id="item.id" type='long'></subtitle>
                <div class="col-6 goods pointer" v-for="(items,index) in item.goodsList" :key="index" @click="goDetails(item.id,items.goodsID)">
                    <img :src="items.img" alt="goods">
                    <p>{{items.goodstitle}}</p>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import subtitle from "../../components/subtitle";
import { productList } from "../../assets/htmlContent/productContent/productList";
export default {
    data() {
        return {
            productList: [],
        };
    },
    components: {
        subtitle,
    },
    methods: {
        goDetails(id, goodsID) {
            // console.log(id, goodsID);
            let spuSwitchList;
            let spuData;
            let productList = this.productList;
            for (let i in productList) {
                if (productList[i].id == id) {
                    spuSwitchList = productList[i];
                    for (let j in productList[i].goodsList) {
                        if (productList[i].goodsList[j].goodsID == goodsID) {
                            spuData = productList[i].goodsList[j];
                        }
                    }
                }
            }
            let productObj = {
                spuSwitchList: spuSwitchList,
                spuData: spuData,
            };
            localStorage.setItem("productObj", JSON.stringify(productObj));
            this.$router.push({
                path: "/ProductDetails",
            });
        },
        toLocal() {
            //查找存储的锚点id
            let Id = this.$route.query.tid;
            if (Id) {
                let top = document.getElementById(Id).offsetTop;
                // console.log(top);
                
                // window.scrollTo({ top: top - 100, behavior: "smooth" });
                document.getElementById('scrolldiv').scrollTo({ top: top - 100, behavior: "smooth" });
            }
        },
    },
    created() {
        this.productList = productList;
        setTimeout(() => {
            this.toLocal();
        }, 500);
    },
};
</script>
<style scoped>
.pointer{
    cursor: pointer;
}
@media (min-width: 975px) {
    .logo {
        margin-top: 60px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }
    .logo > img {
        width: 312px;
        height: 82px;
    }
    .logo > p {
        font-size: 80px;
        margin-left: 75.5px;
        font-weight: bold;
        color: rgba(61, 61, 61, 1);
    }
    .line-height {
        margin-top: 121px;
        margin-bottom: 79px;
    }
    .goods {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-bottom: 60px;
    }
    .goods > img {
        width: 276px;
        height: 276px;
    }
    .goods > p {
        margin-top: 32px;
        font-size: 20px;
        color: rgba(102, 102, 102, 1);
    }
}
@media (max-width: 991px) {
    .logo {
        margin-top: 40px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }
    .logo > img {
        width: 212px;
        height: 62px;
    }
    .logo > p {
        font-size: 55px;
        margin-left: 55.5px;
        font-weight: bold;
        color: rgba(61, 61, 61, 1);
    }
    .line-height {
        margin-top: 70px;
        margin-bottom: 79px;
    }
    .goods {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-bottom: 60px;
    }
    .goods > img {
        width: 200px;
        height: 200px;
    }
    .goods > p {
        margin-top: 32px;
        font-size: 18px;
        color: rgba(102, 102, 102, 1);
    }
}
@media (max-width: 767px) {
    .logo {
        margin-top: 40px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: baseline;
    }
    .logo > img {
        width: 132px;
        height: 32px;
    }
    .logo > p {
        font-size: 30px;
        margin-left: 55.5px;
        font-weight: bold;
        color: rgba(61, 61, 61, 1);
    }
    .line-height {
        margin-top: 30px;
        margin-bottom: 49px;
    }
    .goods {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-bottom: 30px;
    }
    .goods > img {
        width: 96px;
        height: 100px;
    }
    .goods > p {
        margin-top: 16px;
        font-size: 12px;
        color: rgba(102, 102, 102, 1);
    }
}
</style>