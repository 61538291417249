export { json as productList };
const json = [
  {
    id: 0,
    title: "纯奶系列",
    goodsList: [
      {
        goodsID: "qz50",
        img: require("/src/assets/images/goods/qz5.0.png"),
        goodstitle: "渥康 5.0%全脂纯牛奶 800mL",
      },
      {
        goodsID: "qz40",
        img: require("/src/assets/images/goods/4.0.png"),
        goodstitle: "渥康 4.0%全脂纯牛奶 800mL",
      },
      {
        goodsID: "dz10",
        img: require("/src/assets/images/goods/dz1.0.png"),
        goodstitle: "渥康 1.0%低脂纯牛奶 800mL",
      },
      {
        goodsID: "tz01",
        img: require("/src/assets/images/goods/tz0.1.png"),
        goodstitle: "渥康 0.1%脱脂纯牛奶 800mL",
      },
      {
        goodsID: "tz50x",
        img: require("/src/assets/images/goods/5.0x.png"),
        goodstitle: "渥康 5.0%全脂纯牛奶 250mL",
      },
      {
        goodsID: "tz01x",
        img: require("/src/assets/images/goods/0.1x.png"),
        goodstitle: "渥康 0.1%脱脂纯牛奶 250mL",
      },
      {
        goodsID: "zhn",
        img: require("/src/assets/images/goods/zhn.jpg"),
        goodstitle: "渥康 纸盒奶 250mL",
      },
    ],
  },
  {
    id: 1,
    title: "功能系列",
    goodsList: [
      {
        goodsID: "wrt50",
        img: require("/src/assets/images/goods/5.0wrt.png"),
        goodstitle: "渥康 5.0%全脂无乳糖奶 800mL",
      },
      {
        goodsID: "wrt10",
        img: require("/src/assets/images/goods/1.0wrt.png"),
        goodstitle: "渥康 1.0%低脂无乳糖奶 800mL",
      },
      {
        goodsID: "qzwrt",
        img: require("/src/assets/images/goods/qzqkl.png"),
        goodstitle: "渥康 全脂巧克力无乳糖奶 250mL",
      },
    ],
  },
  {
    id: 2,
    title: "有机系列",
    goodsList: [
      {
        goodsID: "yj5_0",
        img: require("/src/assets/images/goods/youji5.0.png"),
        goodstitle: "渥康 5.0%有机全脂纯牛奶 800mL",
      },
      {
        goodsID: "yj1_0",
        img: require("/src/assets/images/goods/youji1.0.png"),
        goodstitle: "渥康 1.0%有机低脂纯牛奶 800mL",
      },
      {
        goodsID: "yja25_0",
        img: require("/src/assets/images/goods/yja2_5.0.jpg"),
        goodstitle: "渥康 有机A2 5.0全脂奶 800mL",
      },
      {
        goodsID: "yja21_0",
        img: require("/src/assets/images/goods/yja2_1.0.jpg"),
        goodstitle: "渥康 有机A2 1.0全脂奶 800mL",
      },
      {
        goodsID: "yja25_0x",
        img: require("/src/assets/images/goods/yja25_0x.jpg"),
        goodstitle: "渥康 有机A2 5.0全脂奶 250mL",
      },
    ],
  },
  {
    id: 3,
    title: "极速系列",
    goodsList: [
      {
        goodsID: "qzx35",
        img: require("/src/assets/images/goods/3.5qzx.jpg"),
        goodstitle: "渥康 3.5%全脂鲜牛奶 225mL",
      },
      {
        goodsID: "qzx1L",
        img: require("/src/assets/images/goods/qzx1L.jpg"),
        goodstitle: "渥康 212全脂鲜牛奶 1L",
      },
      {
        goodsID: "baiqzx35",
        img: require("/src/assets/images/goods/baiqzx3.5.jpg"),
        goodstitle: "渥康 极速白版3.5%全脂鲜牛奶 2L",
      },
      {
        goodsID: "lanqzx35",
        img: require("/src/assets/images/goods/lanqzx3.5.jpg"),
        goodstitle: "渥康 极速蓝版3.5%全脂鲜牛奶 2L",
      },
    ],
  },
  {
    id: 4,
    title: "奶粉系列",
    goodsList: [
      {
        goodsID: "lanxie",
        img: require("/src/assets/images/goods/lanxie.png"),
        goodstitle: "渥康 全脂牛乳粉 1kg",
      },
      {
        goodsID: "luxie",
        img: require("/src/assets/images/goods/luxie.png"),
        goodstitle: "渥康 脱脂牛乳粉 1kg",
      },
    ],
  },
  {
    id: 5,
    title: "休闲系列",
    goodsList: [
      {
        goodsID: "ywnd",
        img: require("/src/assets/images/goods/ywnd_1.jpg"),
        goodstitle: "渥康 原味奶豆 18g",
      },
      {
        goodsID: "qklnd",
        img: require("/src/assets/images/goods/qklnd_1.jpg"),
        goodstitle: "渥康 巧克力粒奶豆 18g",
      },
      {
        goodsID: "cnd",
        img: require("/src/assets/images/goods/cnd.jpg"),
        goodstitle: "渥康 纯奶豆 18g",
      },
    ],
  },
];
